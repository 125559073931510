body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  /* Theme */
  --main-color: #FFC217;
  --fs-5: calc(var(--game-width-content) * 0.0117);
  --fs-7: calc(var(--game-width-content) * 0.015);
  --fs-8: calc(var(--game-width-content) * 0.01869);
  --fs-9: calc(var(--game-width-content) * 0.02102);
  --fs-10: calc(var(--game-width-content) * 0.02336);
  --fs-11: calc(var(--game-width-content) * 0.0257);
  --fs-12: calc(var(--game-width-content) * 0.028037); /*OK*/
  --fs-13: calc(var(--game-width-content) * 0.03037); /*OK*/
  --fs-14: calc(var(--game-width-content) * 0.03271); /*OK*/
  --fs-15: calc(var(--game-width-content) * 0.03504); /*OK*/
  --fs-16: calc(var(--game-width-content) * 0.0373); /*OK*/
  --fs-17: calc(var(--game-width-content) * 0.0397); /*OK*/
  --fs-18: calc(var(--game-width-content) * 0.04205); /*OK*/
  --fs-20: calc(var(--game-width-content) * 0.05); /*OK*/
  --fs-22: calc(var(--game-width-content) * 0.0514); /*OK*/
  --fs-24: calc(var(--game-width-content) * 0.056); /*OK*/
  --fs-25: calc(var(--game-width-content) * 0.0585); /*OK*/
  --fs-28: calc(var(--game-width-content) * 0.0654); /*OK*/
  --fs-30: calc(var(--game-width-content) * 0.07);
  --fs-32: calc(var(--game-width-content) * 0.078);
  --fs-40: calc(var(--game-width-content) * 0.09345);
  --fs-50: calc(var(--game-width-content) * 0.1168);
  --fs-75: calc(var(--game-width-content) * 0.17523);
   /*OK*/

  --inset-top: env(safe-area-inset-top, 0);
  --inset-bottom: env(safe-area-inset-bottom, 0);
  --inset-left: env(safe-area-inset-left, 0);
  --inset-right: env(safe-area-inset-right, 0);
}

.custom-scroll-bar::-webkit-scrollbar{
  width: 0px;
}

.text-size-50{
  font-size: var(--fs-50);
}

.text-size-5{
  font-size: var(--fs-5);
}

.text-size-7{
  font-size: var(--fs-7);
}

.text-size-8{
  font-size: var(--fs-8);
}
.text-size-9{
  font-size: var(--fs-9);
}
.text-size-10{
  font-size: var(--fs-10);
}
.text-size-11{
  font-size: var(--fs-11);
}
.text-size-12{
  font-size: var(--fs-12);
}

.text-size-13{
  font-size: var(--fs-13);
}

.text-size-14{
  font-size: var(--fs-14);
}

.text-size-15{
  font-size: var(--fs-15);
}

.text-size-16{
  font-size: var(--fs-16);
}

.text-size-17{
  font-size: var(--fs-17);
}

.text-size-18{
  font-size: var(--fs-18);
}

.text-size-20{
  font-size: var(--fs-20);
}

.text-size-22{
  font-size: var(--fs-22);
}

.text-size-24{
  font-size: var(--fs-24);
}

.text-size-25{
  font-size: var(--fs-25);
}


.text-size-28{
  font-size: var(--fs-28);
}

.text-size-30{
  font-size: var(--fs-30);
}

.text-size-32 {
  font-size: var(--fs-32);
}

.text-size-40 {
  font-size: var(--fs-40);
}

.text-size-75 {
  font-size: var(--fs-75);
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-Bold.ttf);
  font-family: MontserratBold;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-ExtraBold.ttf);
  font-family: MontserratExtraBold;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-Light.ttf);
  font-family: MontserratLight;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-Medium.ttf);
  font-family: MontserratMedium;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-Regular.ttf);
  font-family: MontserratRegular;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-SemiBold.ttf);
  font-family: MontserratSemiBold;
}

@font-face {
  src: url(../public/fonts/montserrat/Montserrat-Thin.ttf);
  font-family: MontserratThin;
}

.montserrat-bold {
  font-family: MontserratBold;
}

.montserrat-extra-bold {
  font-family: MontserratExtraBold;
}

.montserrat-thin {
  font-family: MontserratThin;
}

.montserrat-light {
  font-family: MontserratLight;
}

.montserrat-medium {
  font-family: MontserratMedium;
}

.montserrat-regular {
  font-family: MontserratRegular;
}

.montserrat-semibold {
  font-family: MontserratSemiBold;
}

.background-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%; /* Tùy chỉnh để giữ tỷ lệ chiều cao (vd: 16:9) */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
